import { History } from "history";
import { get, has, isEmpty, isEqual, orderBy, upperFirst } from "lodash";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";

import { Breadcrumbs, Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import graphic3 from "../../assets/images/illustrations/properties.png";
import { Body, Page } from "../../components/layout";
import { unitImage } from "../../components/listItems/AvailableUnit";
import Title from "../../components/typography/Title";
import LoadingPage from "../../components/ui/LoadingPage";
import ListingDetailsView from "../../components/views/ListingDetailsView";
import { ApplicationState } from "../../store";
import {
  AuthenticatedUser,
  loginWithFacebookPromise,
  loginWithGooglePromise,
} from "../../store/auth";
import {
  fetchActiveListingsByIdPromise,
  fetchPartners,
  fetchRecommendedProperties,
  Listing,
  PartnerProps,
  Unit,
} from "../../store/listings";
import { enqueueSnackbar } from "../../store/notifications";
import { getUnitsByPropertyIdPromise } from "../../store/units";
import { addGTMDataLayer } from "../../utils/GoogleTagManager";
import { validateImg } from "../../utils/imageUrls";
import { removeUrlSpace } from "../../utils/listingUrlHelper";
import { IsTablet } from "../../utils/responsive";
import styled, { mediaDown } from "../../utils/styledComponents";
import { shouldWhiteLabel } from "../../utils/whiteLabel";
import { getUnitPrice } from '../../utils/units.helper';

const StyledLink = styled(Link)`
  && {
    color: ${({ theme }: any) => theme.colors.orange};
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 20px;
  }
`;

const BackIcon = styled(ArrowBackIosIcon)<any>`
  && {
    font-size: 18px;
  }
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  && {
    font-family: ${({ theme }: any) => theme.fonts.MS300};
    font-weight: ${({ theme }: any) => theme.fontWeights.MS300};
    font-size: 18px;
    width: 100%;
    max-width: 1180px;
    padding: 0 20px 20px;
    ${mediaDown.sm`padding: 0 20px 5px;`}
  }
`;

const GridContainer = styled(Grid)`
  display: flex;
`;

const StyledGridContainer = styled(GridContainer)`
  && {
    margin-top: 100px;
  }
`;

const StyledGrid = styled(Grid)`
  && {
    margin-top: 6%;
  }
`;

const GraphicWrapper = styled.div`
  margin-top: 30px;
  ${mediaDown.sm`margin-top: 60px;`};
  margin-bottom: 30px;
  text-align: center;
`;

const Graphic = styled.img`
  height: auto;
  width: 100%;
  max-width: 260px;
  margin-top: -130px;
  ${mediaDown.sm`margin-top: -75px;`}
`;

const TitleWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey3};
`;
interface PropsFromDispatch {
  loginWithGooglePromise: typeof loginWithGooglePromise;
  loginWithFacebookPromise: typeof loginWithFacebookPromise;
  enqueueSnackbar: typeof enqueueSnackbar;
  fetchActiveListingsByIdPromise: typeof fetchActiveListingsByIdPromise;
  getUnitsByPropertyIdPromise: typeof getUnitsByPropertyIdPromise;
  fetchPartners: typeof fetchPartners;
  fetchRecommendedProperties: typeof fetchRecommendedProperties;
}

interface PropsFromState {
  authenticated: boolean;
  user?: AuthenticatedUser;
}

interface OwnProps {
  partners: PartnerProps[];
  property: Listing;
  units: Unit[];
  recommended: Listing[];
  match: any;
  history: History;
}

type AllProps = PropsFromDispatch & OwnProps & PropsFromState;

const mobileStyle = {
  backgroundColor: "white",
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 10,
};

const desktopStyle = { paddingBottom: 20, paddingTop: 20 };

const ListingDetails: React.FunctionComponent<AllProps> = (props) => {
  const { params } = props.match;
  const { property } = props;
  const changeStyle = IsTablet() ? mobileStyle : desktopStyle;
  const [hasErrors, setHasErrors] = useState(false);
  const [hasPartners, setHasPartners] = useState(true);

  const onError = (): void => {
    setHasErrors(true);
  };
  const onLoad = () => {
    if (has(params, "id")) {
      const url = removeUrlSpace(window.location.pathname);
      const fullUrl = url + window.location.search;
      props.history.replace(fullUrl);

      var searchParams = new URLSearchParams(window.location.search);
      const previewToken = searchParams.get("preview_token");

      const query = { id: params.id };
      if (!isEmpty(previewToken)) {
        Object.assign(query, { previewToken });
      }

      props
        .fetchActiveListingsByIdPromise(query)
        .then((data: Listing) => {
          const _hasPartners = get(
            data,
            "organisationObject.settings.partners.showPartners",
            true
          );
          setHasPartners(_hasPartners);
          addGTMDataLayer({
            data: {
              listingId: data._id,
              propertyType: data.propertyType,
              title: data.title,
              organisation: data.organisation,
              suburb: data.address.suburb,
              city: data.address.city,
              province: get(data, "address.province", ""),
              listingType: get(data, "listingType", ""),
              price: getUnitPrice(get(data, "units[0]", {})) || 0,
            },
            event: "tenant_viewed_listing",
            page: "listingDetail",
            user: !isEmpty(props.user)
              ? { id: props.user?.id, displayName: props.user?.displayName }
              : undefined,
          });

          const hasUnits = get(data, "hasUnits");

          //get units by propertyId if data.hasUnits is true
          if (hasUnits) {
            props
              .getUnitsByPropertyIdPromise({ propertyId: data._id })
              .catch((err) => {
                console.log(err);
              });
          }

          // get recommendations by property id
          props.fetchRecommendedProperties(params.id);
          // get partners
          if (_hasPartners) {
            props.fetchPartners();
          }

          setHasErrors(false);
        })
        .catch(() => onError());
    }
  };
  useEffect(onLoad, []);
  const tempPartners =
    !hasErrors && hasPartners
      ? props.partners.map((partner: PartnerProps) => ({
          ...partner,
          title: partner.mainHeading,
          description: partner.mainDescription,
          index: Math.floor(Math.random() * 100 + 1),
        }))
      : [];

  // sort by random index
  const partners = !isEmpty(tempPartners)
    ? orderBy(tempPartners, ["index"], ["asc"])
    : [];

  const isResidential = isEqual(
    get(props, "property.listingSector"),
    "residential"
  );

  const pageTitle = (): string => {
    if (hasErrors) {
      return "Property not found";
    }
    const listingType = props.property.listingType;

    const isPropertyHavingMultipleUnits = get(
      props.property,
      "hasUnits",
      false
    );

    if (isPropertyHavingMultipleUnits) {
      return `Property for ${listingType} in ${props.property.address.suburb} - Flow`;
    }

    return isResidential
      ? `${
          props.property.units[0].bedrooms
        } Bedroom ${props.property.propertyType.toLowerCase()} for ${listingType} in ${
          props.property.address.suburb
        }  - Flow`
      : `${upperFirst(
          props.property.propertyType.toLowerCase()
        )} for ${listingType} in ${props.property.address.suburb}  - Flow`;
  };

  const propertyWithUnits = !isEmpty(props.units)
    ? { ...property, units: props.units }
    : property;

  const emptyState = (
    <StyledGridContainer item={true} xs={true}>
      <Grid container={true} justify="center">
        <StyledGrid item={true} xl={5} lg={5} sm={6}>
          <GraphicWrapper>
            <Graphic src={graphic3} />
          </GraphicWrapper>
          <TitleWrapper>
            <Title>This property is no longer listed</Title>
            <p>
              This property could have been sold, rented out or taken off the
              market and will not appear here.
            </p>
          </TitleWrapper>
        </StyledGrid>
      </Grid>
    </StyledGridContainer>
  );
  const organisation = get(property, "organisationObject");
  const _shouldWhiteLabel = shouldWhiteLabel(organisation);

  return has(params, "id") && !isEmpty(property) ? (
    <Page>
      <Helmet>
        <title>{pageTitle()}</title>
        <meta property="og:title" content={props.property.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={validateImg(unitImage(props.property.units[0]), "medium")}
        />
        <meta name="description" content={props.property.description} />
      </Helmet>
      <Body
        style={{ ...changeStyle }}
        shouldWhiteLabel={_shouldWhiteLabel}
        hasStickyFooter={true}
      >
        <Grid container={true} justify="center">
          {false && (
            <StyledBreadcrumbs aria-label="breadcrumb">
              <StyledLink to="/listings?listingType=sale">
                <BackIcon /> Back to listings
              </StyledLink>
            </StyledBreadcrumbs>
          )}
          <Grid item={true} xl={8} sm={12} xs={12}>
            <ListingDetailsView
              {...{
                recommended: props.recommended,
                partners,
                property: propertyWithUnits,
              }}
            />
          </Grid>
        </Grid>
      </Body>
    </Page>
  ) : !hasErrors ? (
    <LoadingPage />
  ) : (
    emptyState
  );
};

const mapStateToProps = ({
  auth,
  chats,
  listing,
  units,
}: ApplicationState) => ({
  authenticated: auth.authenticated,
  chats,
  errors: auth.errors,
  property: listing.property,
  user: auth.user,
  partners: listing.partners,
  recommended: listing.recommended,
  units: units.units,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    { enqueueSnackbar, fetchPartners, fetchRecommendedProperties },
    dispatch
  ),
  ...bindPromiseCreators(
    { fetchActiveListingsByIdPromise, getUnitsByPropertyIdPromise },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingDetails);
