import { History } from "history";
import * as React from "react";
import { withRouter } from "react-router";

import MuiAppBar, { AppBarProps } from "@material-ui/core/AppBar";
import MuiButton from "@material-ui/core/Button";
import MuiToolbar from "@material-ui/core/Toolbar";

import { AuthenticatedUser } from "../../store/auth";
import styled, { mediaDown, mediaUp } from "../../utils/styledComponents";
import { MenuIcon } from "../icons";
import { Organisation } from "../../store/organisation";
import { getOrganisationLogo } from "../../utils/whiteLabel";
import Link from "@material-ui/core/Link/Link";

const StyledAppBar = styled(
  MuiAppBar as React.FunctionComponent<
    AppBarProps & { shouldWhiteLabel?: boolean }
  >
)`
  && {
    background-color: ${({ theme }) => theme.colors.white};
    height: 60px;
    box-shadow: none;
    font-size: ${({ theme }) => theme.fontSizes.navbarItem};
    font-family: ${({ theme }) => theme.fonts.MS500};
    ${({ shouldWhiteLabel }) => (shouldWhiteLabel ? "margin-top: -5px;" : "")};
  }
`;

const StyledToolBar = styled(MuiToolbar)`
  justify-content: center;
`;

const Logo = styled.img<{ shouldWhiteLabel?: boolean }>`
  && {
    ${({ shouldWhiteLabel }) =>
      shouldWhiteLabel
        ? "height: 50px; margin-top: 5px; max-width: 500px;"
        : "height: 30px"};
    border-radius: 5px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const RightMenuItems = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
  height: 100%;
  text-align: right;
  color: ${({ theme }) => theme.colors.grey4};
`;

const DesktopToolbar = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  max-width: 1180px;
  ${mediaDown.md`display: none;`}
`;

const MobileToolbar = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  ${mediaUp.md`display: none;`}
`;

const Container = styled.div`
  display: flex;
  ${mediaUp.md`flex-direction: column`};
  ${mediaDown.md`flex-direction: column-reverse`};
`;

const AppliedFilterButton = styled(MuiButton)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS500};
    margin: 0 15px 0 0;
    padding: 0;
    text-transform: none;
    color: ${({ theme }) => theme.colors.orange};
  }
`;

const StyledFilterIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 10px;
`;

interface OwnProps {
  title: string;
  showTitle?: boolean;
  centerMenuComponent: React.FunctionComponent<{}> | null;
  rightMenuComponent: React.FunctionComponent<{}> | null;
  mobileToggle: React.ReactEventHandler;
  authenticated: boolean;
  showListingsFilterBlock: boolean;
  user: AuthenticatedUser;
  showDialog: (event: React.SyntheticEvent) => void;
  toggleListingsFilter: (event: React.SyntheticEvent) => void;
  hideNav?: boolean;
  unreadMessageCount: number;
  link?: string;
  organisation?: Organisation;
}
interface ConnectedProps {
  history: History;
}

type AllProps = OwnProps & AppBarProps & ConnectedProps;

const NavBar: React.FunctionComponent<AllProps> = ({
  rightMenuComponent,
  mobileToggle,
  showListingsFilterBlock,
  toggleListingsFilter,
  hideNav,
  link,
  unreadMessageCount,
  user,
  organisation,
}) => {
  const { shouldWhiteLabel, logo } = getOrganisationLogo(organisation);
  const icon =
    "iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAMKADAAQAAAABAAAAMAAAAADbN2wMAAADC0lEQVRoBe1Zz08TQRR+b5cqJSqox9IYTxr5B7zorX+CP4IHafBWNComXoyi8aCJKAe8YYIHL4ST1x69+A800ZOGgje0IEGw7Y5vlnaX7o9h2L4diDBJ05k3b9583+zszPuyCHtUxNzQEVj8dQsEFEDgkAsDRQUQyjB4cgavVv7qQEMdJ24f8Tp/CZzme4p7Nib2N7Dsm3i/+imm3zMbJyAmcxcBRJlW/piHIqqCsAaABRxf+hzV3bZZ7YqJf3fbAMzvCF6C2SI43xoTC88oAajWiiBEzkOD0AQLXoCdueD+ZF3a2kX6LqyMtJtR/yim8peh4bylznyUA6sNoY8IZPyY1jN8sPjEb9PCvxp8CuA89myIdXoa617br1Shxyr1QKM5S7a4l8l356iJQBB0ZgIW2vZkE+AT2CLcH/ID6JfY5RbKRnSaMfVlN0MT2Ue1js/WuKwFiHeI9kookAnD+ub10DTN+rWQLdJAmAm7e4wKMWHB1OyJSD9Oo1Mv0Tvw3AuJ+Ifq96B34INr26jdoP835OPvCsRHYGXkO9pZ7o6sIk44Ru8B8e7ccaitfSeApzrRgNNqd56KiD+J3BksVehOiC6dA6J92Kw4+vU3nTNFCtgIBJU4gljIRxRV4GWM4KBAXP4mjv/4SHt3WP3euft72PXdAYLRLbQdi5jOnYYNfEirXCD7+VbfFyJWhl7xEseWlrf77+u6mLtiuwfJvkaZEjjtLcSVv3Pz0CLAmb8bJ8Cdv3MTUB6jaeTvRgmkkb9zE1DrAd78nRu7hh7gzd+5CSTQA93n79wkdqkHusrfubFTvmTjbfceiNUDnPk7N34dPZBG/s7NQ3kPpJG/GyUgJ+PO37kJaOVCctLD/J176f+XeNpbiJswl77YEwKc+sI4AW59obwHUtk2B+77gPymoChqPaAYmKiLV19o6IFEKBWDePVFAj2gwJaoq3t9sUs9kAilYlBX+kJHDyjmTtTFqS909EAikIpBaegLo/dAGvrCKAH5cDx9gbAa/7AOvw/Er00aPQf6+8A/bGW8jO9I+QEAAAAASUVORK5CYII=";

  const displayLogo = () => (
    <Link href={link ? link : "/"}>
      <Logo shouldWhiteLabel={shouldWhiteLabel} src={logo} />
    </Link>
  );

  return (
    <Container>
      {/* <DownloadBanner showDialog={showDialog} /> */}
      <StyledAppBar shouldWhiteLabel={shouldWhiteLabel} position="static">
        <StyledToolBar>
          <DesktopToolbar>
            {displayLogo()}
            {rightMenuComponent && !hideNav && (
              <RightMenuItems>{rightMenuComponent({})}</RightMenuItems>
            )}
          </DesktopToolbar>
          <MobileToolbar>
            <LogoWrapper>{displayLogo()}</LogoWrapper>
            {showListingsFilterBlock && (
              <AppliedFilterButton onClick={toggleListingsFilter}>
                Filters{" "}
                <StyledFilterIcon src={`data:image/png;base64,${icon}`} />
              </AppliedFilterButton>
            )}
            {hideNav && (
              <>
                <MenuIcon onClick={mobileToggle} />
              </>
            )}
          </MobileToolbar>
        </StyledToolBar>
      </StyledAppBar>
    </Container>
  );
};

export default withRouter(NavBar as React.FC<any>);
