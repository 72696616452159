import { get, has } from "lodash";
import { Reducer } from "redux";

import { addGTMDataLayer } from "../../utils/GoogleTagManager";
import {
  createChat,
  getProfile,
  sendEnquiry,
  setChatUnreadCount,
} from "./routines";
import { Chat, ChatState } from "./types";

const initialState: ChatState = {
  chats: [],
  errors: undefined,
  listLoading: false,
  enquiryResponse: undefined,
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case sendEnquiry.TRIGGER:
    case createChat.TRIGGER: {
      // todo find a way to send inquiry with user data
      // if (has(action, "payload.data.chat")) {
      //   addAnalytics(action.payload, "tenant_initiated_message");
      // }
      return { ...state, listLoading: true, errors: undefined };
    }

    case getProfile.TRIGGER: {
      return { ...state, errors: undefined };
    }

    case setChatUnreadCount.TRIGGER: {
      return { ...state, unreadCount: 0 };
    }

    // Success
    case createChat.SUCCESS: {
      if (has(action, "payload.data.chat")) {
        addAnalytics(action.payload.data.chat, "tenant_sent_message");
      }
      return {
        ...state,
      };
    }

    case sendEnquiry.SUCCESS: {
      if (has(action, "payload.payload")) {
        addGTMDataLayer({
          data: {
            listingId: get(action, "payload.payload.listingId", ""),
            title: get(action, "payload.property.propertyName", ""),
            user: get(action, "payload.payload.user._id", undefined),
            price: get(action, "payload.payload.price", ""),
            listingType: get(action, "payload.payload.listingType", ""),
            province: get(action, "payload.payload.province", ""),
            suburb: get(action, "payload.payload.suburb", ""),
            city: get(action, "payload.payload.city", ""),
            organisation: get(action, "payload.payload.organisation", ""),
          },
          event: "tenant_sent_message",
          page: "listingsDetail",
        });
      }
      return {
        ...state,
        enquiryResponse: action.payload,
      };
    }

    case setChatUnreadCount.SUCCESS: {
      return {
        ...state,
        unreadCount: action.payload,
      };
    }

    case getProfile.SUCCESS: {
      return { ...state, chats: action.payload };
    }

    // Failure
    case sendEnquiry.FAILURE:
    case createChat.FAILURE: {
      return { ...state, errors: action.payload };
    }

    // Fullfill
    case sendEnquiry.FULFILL:
    case createChat.FULFILL: {
      return {
        ...state,
      };
    }

    case setChatUnreadCount.FULFILL: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
};

const addAnalytics = (chat: Chat, eventType: string) => {
  addGTMDataLayer({
    data: chat,
    event: eventType,
    page: "chat-messages",
  });
};

export { reducer as chatReducer };
