import React from "react";
import { OrganisationColors } from "../../store/organisation";
import styled, { mediaDown } from "../../utils/styledComponents";
import { getOrganisationColor } from "../../utils/whiteLabel";
import MuiButton from "@material-ui/core/Button";

interface OwnProps {
  isMore?: boolean;
  orgColors?: OrganisationColors;
}

interface PropsFromDispatch {
  onClick?: (Event: React.MouseEvent) => void;
}

type AllProps = OwnProps & PropsFromDispatch;

const StyledReadMore = styled(MuiButton)<AllProps>`
  && {
    font-size: 16px;
    color: ${({ theme, orgColors }) =>
      getOrganisationColor(orgColors, "primaryColor", theme.colors.orange)};
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    cursor: pointer;
    text-decoration: underline;
    display: ${({ isMore }) => (!isMore ? "none" : "inherit")};
    width: 80px;
    ${mediaDown.sm`font-size: 14px; line-height: 21px;`}
    padding: 0px;
    margin-bottom: 14px;
    text-transform: none;
    white-space: nowrap;
    &:hover {
      background-color: transparent; /* Remove the background color on hover */
    }
  }
`;

const ReadMore: React.FunctionComponent<AllProps> = ({ ...props }) => (
  <StyledReadMore {...props} onClick={props.onClick} />
);

export default ReadMore;
